import React from "react";
import { Button, Wrapper } from "@wisr/react-ui";
import { analyticsViewPageEvent } from "../../shared/analytics/analytics.utils";
import { isBrowser } from "@wisr/common";
import indexGlobalStyle from "./index.global.scss";
import indexLocalStyle from "./index.scss";
import { Link } from "gatsby";
import image from "../../assets/images/404.svg";
import { HelmetSeoComponent } from "../../layout/helmet-seo.component";

const FourOFourPage = () => {
  if (isBrowser()) {
    analyticsViewPageEvent("404");
  } else {
    return null;
  }
  return (
    <Wrapper>
      <HelmetSeoComponent
        title="404 Not Found"
        description="The content you're looking for doesn't exist. Head to our
            homepage to get back on the right track."
      />

      <div className="content">
        <style jsx>{indexGlobalStyle}</style>
        <style jsx>{indexLocalStyle}</style>
        <div className="text-container">
          <h1>404 Not Found</h1>
          <h2>It looks like we hit a brick wall</h2>
          <p>
            The content you&apos;re looking for doesn&apos;t exist. Head to our
            homepage to get back on the right track.
          </p>
          <Button button="primary" theme="navy" size="medium">
            <Link to="/" id="404-homepage-button">
              Go to homepage
            </Link>
          </Button>
        </div>

        <div className="image-container">
          <img src={image} />
        </div>
      </div>
    </Wrapper>
  );
};

export default FourOFourPage;
